@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&display=swap');

.lora-ambassador {
  font-family: "Lora", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

.jodit-wysiwyg {
  width: 210mm;
  height: 297mm;
  display: block;
  margin: 100px auto;
  font-family: Arial, Helvetica, sans-serif;
  // font-family: lora-ambassador, Roboto;
  font-size: 16px;
  line-height: 16px;

  @media only screen and (min-width: 900px) {
    padding: 0 50px !important;
  }

  // h1 {
  //   font-family: lora-ambassador, Roboto;
  // }

  // p {
  //   font-family: Roboto !important;
  // }

  .pagebreak {
    border: 1px dashed grey;
    color: grey;
    text-align: center;
    font-size: 13px;
    padding: 3px;
    margin: 10px 0;
  }
  .pagebreak-hidden {
    font-size: 13px;
    padding: 3px;
    margin: 10px 0;
  }

  .custom-addendum-block {
    border: 3px dashed gray;
    display: inline-block;
    background-color: #cecece;
    padding: 5px;
    position: relative;
  }
  .custom-addendum-block-id {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    display: none;
  }
  .custom-addendum-block-id-hidden {
    display: none;
  }

  .addendum {
    margin: 10px 0;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 8px solid #AD882F;
    padding: 5px;
    border-radius: 10px;
  }

}
